
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LanguageSelector extends Vue {
  languages = [
    {
      label: 'Ελληνικά',
      value: 'el',
    },
    {
      label: 'English',
      value: 'en',
    },
  ];
  selectedLanguage = 'el';

  handleSelectLanguage(event: Event) {
    this.selectedLanguage =
      (event?.target as HTMLInputElement)?.dataset?.language ??
      this.languages[0].value;
    this.$i18n.locale = this.selectedLanguage;
  }

  getAlt(language: string) {
    return `${language}-flag`;
  }
}
