<template>
  <!-- Sidebar-->
  <aside
    :class="['bg-dark d-flex flex-column overflow-auto', { 'is-open': isOpen }]"
  >
    <RouterLink
      :to="{ name: 'home' }"
      class="text-center logo"
      data-test="sidebar-home"
    >
      <img
        class="d-block mx-auto my-2"
        src="@/assets/img/logo_horizontal_white.svg"
        alt="Logo"
      />
    </RouterLink>

    <ul class="m-0 p-0">
      <li>
        <RouterLink
          :to="{ name: 'home' }"
          active-class="active"
          exact
          class="p-3 d-block nav__item"
          data-test="sidebar-home"
        >
          <BIcon icon="house-door" />
          <span class="ml-3 link-text">
            {{ $t('components.sidebar.menuItems.home') }}
          </span>
        </RouterLink>
      </li>

      <li>
        <RouterLink
          :to="{ name: 'products' }"
          active-class="active"
          class="p-3 d-block nav__item border-top"
          data-test="sidebar-products"
        >
          <BIcon icon="upc-scan" />
          <span class="ml-3 link-text">
            {{ $t('components.sidebar.menuItems.products') }}
          </span>
        </RouterLink>

        <ul v-if="isOpen" class="m-0 p-0">
          <li>
            <RouterLink
              :to="{ name: 'brands' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-brands"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.brands') }}
              </span>
            </RouterLink>
          </li>

          <li>
            <RouterLink
              :to="{ name: 'categories' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-categories"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.categories') }}
              </span>
            </RouterLink>
          </li>

          <li>
            <RouterLink
              :to="{ name: 'filterGroups' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-filterGroups"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.filterGroups') }}
              </span>
            </RouterLink>
          </li>
        </ul>
      </li>

      <li>
        <RouterLink
          :to="{ name: 'orders' }"
          active-class="active"
          class="p-3 d-block nav__item border-top"
          data-test="sidebar-orders"
        >
          <BIcon icon="box-seam" />
          <span class="ml-3 link-text">
            {{ $t('components.sidebar.menuItems.orders') }}
          </span>
        </RouterLink>
      </li>

      <li>
        <RouterLink
          :to="{ name: 'customers' }"
          active-class="active"
          class="p-3 d-block nav__item border-top"
          data-test="sidebar-customers"
        >
          <BIcon icon="people" />
          <span class="ml-3 link-text">
            {{ $t('components.sidebar.menuItems.customers') }}
          </span>
        </RouterLink>
      </li>

      <li v-if="getEnabledService('marketing')">
        <RouterLink
          :to="{ name: 'coupons' }"
          active-class="active"
          class="p-3 d-block nav__item border-top"
          data-test="sidebar-marketing"
        >
          <BIcon icon="trophy" />
          <span class="ml-3 link-text">
            {{ $t('components.sidebar.menuItems.marketing') }}
          </span>
        </RouterLink>

        <ul v-if="isOpen" class="m-0 p-0">
          <li>
            <RouterLink
              :to="{ name: 'coupons' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-coupons"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.coupons') }}
              </span>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              :to="{ name: 'promos' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-promos"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.promos') }}
              </span>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              :to="{ name: 'newsletters' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-newsletters"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.newsletters') }}
              </span>
            </RouterLink>
          </li>
        </ul>
      </li>

      <li v-if="getEnabledService('blog')">
        <RouterLink
          :to="{ name: 'posts' }"
          active-class="active"
          class="p-3 d-block nav__item border-top"
          data-test="sidebar-blog"
        >
          <BIcon icon="book" />
          <span class="ml-3 link-text">
            {{ $t('components.sidebar.menuItems.blog') }}
          </span>
        </RouterLink>

        <ul v-if="isOpen" class="m-0 p-0">
          <li>
            <RouterLink
              :to="{ name: 'posts' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-posts"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.posts') }}
              </span>
            </RouterLink>
          </li>

          <li>
            <RouterLink
              :to="{ name: 'postCategories' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-postCategories"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.postCategories') }}
              </span>
            </RouterLink>
          </li>

          <li>
            <RouterLink
              :to="{ name: 'authors' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-authors"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.authors') }}
              </span>
            </RouterLink>
          </li>
        </ul>
      </li>

      <li>
        <RouterLink
          :to="{ name: 'stats' }"
          active-class="active"
          class="p-3 d-block nav__item border-top"
          data-test="sidebar-stats"
        >
          <BIcon icon="pie-chart" />
          <span class="ml-3 link-text">
            {{ $t('components.sidebar.menuItems.stats') }}
          </span>
        </RouterLink>
      </li>

      <li>
        <RouterLink
          :to="{ name: 'content' }"
          active-class="active"
          class="p-3 d-block nav__item border-top"
          data-test="sidebar-blog"
        >
          <BIcon icon="laptop" />
          <span class="ml-3 link-text">
            {{ $t('components.sidebar.menuItems.content') }}
          </span>
        </RouterLink>

        <ul v-if="isOpen" class="m-0 p-0">
          <li>
            <RouterLink
              :to="{ name: 'content.pages' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-posts"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.contentPages') }}
              </span>
            </RouterLink>
          </li>

          <li>
            <RouterLink
              :to="{ name: 'content.banners' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-posts"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.banners') }}
              </span>
            </RouterLink>
          </li>

          <li>
            <RouterLink
              :to="{ name: 'content.questions' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-posts"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.faq') }}
              </span>
            </RouterLink>
          </li>
        </ul>
      </li>

      <li>
        <RouterLink
          :to="{ name: 'settings' }"
          active-class="active"
          class="p-3 d-block nav__item border-top"
          data-test="sidebar-settings"
        >
          <BIcon icon="gear" />
          <span class="ml-3 link-text">
            {{ $t('components.sidebar.menuItems.settings') }}
          </span>
        </RouterLink>

        <ul v-if="isOpen" class="m-0 p-0">
          <li>
            <RouterLink
              :to="{ name: 'settings.checkout-methods' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-checkout-methods"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.checkoutMethods') }}
              </span>
            </RouterLink>
          </li>

          <li>
            <RouterLink
              :to="{ name: 'settings.send-methods' }"
              active-class="active"
              class="py-3 d-block nav__subitem border-top text-decoration-none"
              data-test="sidebar-send-methods"
            >
              <span class="link-text">
                {{ $t('components.sidebar.menuItems.sendMethods') }}
              </span>
            </RouterLink>
          </li>
        </ul>
      </li>
    </ul>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      getEnabledService: 'organization/getEnabledService',
    }),
  },
};
</script>

<style scoped lang="scss">
aside {
  bottom: 0;
  position: fixed;
  top: 0;
  transition: width 0.3s;
  width: 4.375rem;
  will-change: width;
  z-index: 100;

  .logo {
    padding: 0.3125rem;

    img {
      width: 3.75rem;
      will-change: width, height;
      transition: width 0.3s;
    }
  }

  .link-text {
    display: none;
    font-size: 0;
    transition: font-size 0.3s;
  }

  ::v-deep .b-icon {
    margin-left: 8px;
    transition: all 0.3s;
  }

  &.is-open {
    width: 15rem;

    ::v-deep .b-icon {
      display: inline-block;
      margin: auto;
      transition: all 0.3s;
    }

    .link-text {
      display: inline-block;
      font-size: 1rem;
      transition: font-size 0.3s;
    }

    .logo {
      padding: 1.875rem;

      img {
        width: 9.5rem;
        will-change: width, height;
        transition: width 0.3s;
      }
    }
  }

  .border-top {
    border-top-color: $gray-800 !important;
  }

  .nav__item {
    color: white;
    text-decoration: none;
    transition: color 0.3s;

    .b-icon {
      opacity: 0.6;
      transition: opacity 0.3s;
    }

    &:hover {
      opacity: 1;

      .b-icon {
        opacity: 1;
      }
    }

    &.active {
      color: $primary;
      opacity: 1;

      .b-icon {
        opacity: 1;
      }
    }
  }

  .nav__subitem {
    color: white;
    padding-left: 3rem;
    transition: color 0.3s;

    &:hover {
      opacity: 1;
    }

    &.active {
      color: $primary;
    }
  }
}
</style>
