<template>
  <PagePreloader v-if="isLoading" :is-loading="isLoading" />

  <Layout v-else>
    <RouterView />
  </Layout>
</template>

<script>
import PagePreloader from '@/components/PagePreloader';
import Layout from '@/components/Layout';

export default {
  components: {
    PagePreloader,
    Layout,
  },
  data: () => ({
    isLoading: false,
  }),

  created() {
    this.isLoading = true;
  },

  mounted() {
    this.isLoading = false;
  },
};
</script>
