<template>
  <div :class="['page-loading', { active: isLoading }]">
    <div class="page-loading-inner">
      <div class="page-spinner"></div>
      <span>Loading the admin panel</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.page-loading {
  background-color: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  transition: all 0.4s 0.2s ease-in-out;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 9999;

  .page-loading-inner {
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.2s ease-in-out;
    width: 100%;

    span {
      color: #737491;
      display: block;
      font-size: 1rem;
      font-weight: normal;
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .page-loading-inner {
      opacity: 1;
    }
  }

  .page-spinner {
    animation: spinner 0.75s linear infinite;
    border: 0.15em solid $primary;
    border-right-color: transparent;
    border-radius: 50%;
    display: inline-block;
    height: 2.75rem;
    margin-bottom: 0.75rem;
    vertical-align: text-bottom;
    width: 2.75rem;
  }
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}
</style>
