<template>
  <header :class="['navbar bg-light px-0', { 'is-open': isOpen }]">
    <BRow class="justify-content-between w-100">
      <BButton
        v-if="isOpen"
        class="btn-toggle-menu"
        @click="toggleSidebar(false)"
        data-test="toggle-sidebar-close"
      >
        <BIcon icon="chevron-bar-left" class="ml-4" />
      </BButton>

      <BButton
        v-else
        class="btn-toggle-menu"
        @click="toggleSidebar(true)"
        data-test="toggle-sidebar-open"
      >
        <BIcon icon="chevron-bar-right" class="ml-4" />
      </BButton>
      <div>
        <LanguageSelector v-if="showLanguageSelector" />
        <BDropdown right>
          <template v-slot:button-content>
            <BAvatar variant="secondary" class="mr-1" size="2rem" />
            {{ user.name }}
            <BIcon icon="chevron-down" class="font-size-sm ml-1" />
          </template>

          <BDropdownItem :to="{ name: 'organization' }" active-class="active">
            <BIcon icon="building" class="mr-1" />
            Οργανισμός
          </BDropdownItem>

          <BDropdownDivider />
          <BDropdownItem @click="handleLogout">
            <BIcon icon="box-arrow-left" class="mr-1" />
            Αποσύνδεση
          </BDropdownItem>
        </BDropdown>
      </div>
    </BRow>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LanguageSelector from './LanguageSelector';

export default {
  components: {
    LanguageSelector,
  },

  data() {
    return {
      showLanguageSelector: false,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      user: 'session/getUser',
    }),
  },

  methods: {
    ...mapActions({ logoutUser: 'session/logoutUser' }),

    toggleSidebar(value) {
      this.$emit('toggle-sidebar', value);
    },

    async handleLogout() {
      try {
        await this.logoutUser();

        this.$router.push('/login');
      } catch (err) {
        console.log(err);
        this.$router.push('/login');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.navbar {
  box-shadow: 7px 0.125rem 0.625rem -0.1875rem rgba(0, 0, 0, 0.1);
  height: 46px;
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
  position: fixed;
  right: 0;
  top: 0;
  transition: width 0.3s;
  width: calc(100% - 4.375rem);
  z-index: 99;

  &.is-open {
    width: calc(100% - 15rem);
  }

  .btn-toggle-menu,
  .btn-toggle-menu:active,
  ::v-deep .dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  ::v-deep .dropdown-toggle {
    font-weight: 400;
  }

  ::v-deep .dropdown-menu {
    top: 6px !important;
  }
}
</style>
