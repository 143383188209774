<template>
  <div class="d-flex flex-grow-1">
    <Navbar
      :isOpen="isOpen"
      @toggle-sidebar="handleToggleSidebar"
      class="d-print-none"
    />
    <Sidebar data-test="sidebar" :isOpen="isOpen" class="d-print-none" />

    <main :class="['w-100 pb-4', { 'is-open': isOpen }]">
      <BContainer :fluid="true">
        <slot />
      </BContainer>
    </main>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Sidebar from './components/Sidebar.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    Navbar,
    Sidebar,
  },

  data: () => ({
    isOpen: true,
  }),

  async created() {
    await this.getOrganization();

    window.addEventListener('resize', e =>
      this.handleResize(e.target.innerWidth),
    );

    this.handleResize(window.innerWidth);
  },

  destroyed() {
    window.removeEventListener('resize', e =>
      this.handleResize(e.target.innerWidth),
    );
  },

  methods: {
    ...mapActions({
      getOrganization: 'organization/getOrganization',
    }),

    handleToggleSidebar(value) {
      this.isOpen = value;
    },

    handleResize(innerWidth) {
      if (innerWidth < 1024) {
        this.handleToggleSidebar(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  padding-top: 47px;
  margin-left: 4.375rem;
  transition: margin 0.3s;

  &.is-open {
    margin-left: 15rem;
  }

  @media print {
    padding: 0 !important;
    margin-left: 0 !important;
  }
}
</style>
